exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barbara-kasten-works-js": () => import("./../../../src/pages/barbara-kasten-works.js" /* webpackChunkName: "component---src-pages-barbara-kasten-works-js" */),
  "component---src-pages-in-aller-munde-js": () => import("./../../../src/pages/in-aller-munde.js" /* webpackChunkName: "component---src-pages-in-aller-munde-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-macht-licht-js": () => import("./../../../src/pages/macht-licht.js" /* webpackChunkName: "component---src-pages-macht-licht-js" */),
  "component---src-pages-mischa-kuball-referenzraeume-js": () => import("./../../../src/pages/mischa-kuball-referenzraeume.js" /* webpackChunkName: "component---src-pages-mischa-kuball-referenzraeume-js" */),
  "component---src-pages-oelspuren-js": () => import("./../../../src/pages/oelspuren.js" /* webpackChunkName: "component---src-pages-oelspuren-js" */),
  "component---src-pages-oil-js": () => import("./../../../src/pages/oil.js" /* webpackChunkName: "component---src-pages-oil-js" */),
  "component---src-pages-re-inventing-piet-js": () => import("./../../../src/pages/re-inventing-piet.js" /* webpackChunkName: "component---src-pages-re-inventing-piet-js" */),
  "component---src-pages-ulrich-hensel-zwischenwelten-js": () => import("./../../../src/pages/ulrich-hensel-zwischenwelten.js" /* webpackChunkName: "component---src-pages-ulrich-hensel-zwischenwelten-js" */)
}

